import React, { useState } from "react"
import { useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text, TimezonePicker } from "components/Form"
import { Dropdown, FormControl } from "react-bootstrap"
import { locations } from "state_management"
import * as countryCodes from "country-codes-list";

// Location config used by admins.
export default function LocationConfig({ location, onClose }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(location ? location.name : "")
  const [timezone, setTimezone] = useState(location ? location.timezone : "Europe/Copenhagen")
  const [selectedCountry, setSelectedCountry] = useState(location ? location.country : "DK")
  const countryCodeStrings = countryCodes.customList(
    "countryCode",
    "[{countryCode}] {countryNameEn}"
  );
  const [searchTerm, setSearchTerm] = useState('')
  const filteredCountryList = Object.entries(countryCodeStrings).filter(([code, label]) =>
    label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <ConfigContainer
      className="location-config"
      title={location ? "Edit location name" : "Add location"}
      deleteIsImportant
      deleteHandler={location ? () => dispatch(locations.remove(location.id)).then(onClose) : undefined}
      submitHandler={() => {
        const res = { name, timezone, country: selectedCountry }
        if (location) {
          dispatch(locations.edit(location.id, res)).then(onClose)
        } else {
          dispatch(locations.create(res)).then(onClose)
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Location Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
      <Container>
        <Title>Timezone</Title>
        <TimezonePicker required value={timezone} onChange={setTimezone} nonMobileView/>
      </Container>
      <Container>
        <Title>Country</Title>
      <div className="country-dropdown">
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-country">
          {selectedCountry}
        </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* Search input */}
              <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Search country"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              {filteredCountryList.map(([code, label]) => (
                <Dropdown.Item
                  key={code}
                  onClick={() => {
                    setSelectedCountry(code)
                    setSearchTerm('') // Reset search after selection
                  }}
                >
                  {label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
      </Dropdown>
      </div>
    </Container>
    </ConfigContainer>
  )
}
